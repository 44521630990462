import React, { useEffect} from "react";
import { useInView } from "react-intersection-observer";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import "../styles/Hero.scss";


function Hero(props) {
  

  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  useEffect(() => {
    AOS.init();

    if (inView) {
      props.setHero(true);
    } else {
      props.setHero(false);
    }
  }, [inView]);

  return (
    <div className="parent hero" ref={ref}>
      {props.isHero && (
        <>
          <div className="full-bg bg-img-cover hero-bg"></div>
          <div className="hero-container container">
            <div className="hero-left">
              <div className="heading">
                <h1
                  data-aos="fade-up"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                >
                  Prajakta <br />
                  Marwaha
                </h1>
                <span className="dot"></span>
              </div>

              <p
                data-aos="fade-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
                data-aos-delay="500"
                id="hero-para"
              >
               As an ambitious soul, my dreams take root in the soil of humble beginnings, nurtured by hope and hard work. A mother, a traveller at heart, a friend, and a socially conscious individual. My journey is grounded in gratitude and humility, the pillars shaping the essence of my life.
              </p>
              <a
                href="#contact" 
                className="btn"
                data-aos="fade-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
                data-aos-delay="1000"
                data-aos-anchor="#hero-para"
              >
                Let's Talk
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Hero;
