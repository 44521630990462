import React, { useState,useRef, useEffect } from "react";
import "../styles/Blogs_section.scss";
import Blog_card from "./Blog_card";
import AOS from "aos";
import "aos/dist/aos.css";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";


import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

function Blogs_section() {
  const [isBlogVisible, setIsBlogVisible] = useState(true);

  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  useEffect(() => {
    AOS.init();

    if (inView) {
      setIsBlogVisible(true);
    } else {
      setIsBlogVisible(false);
    }
  }, [inView]);

  return (
    <div className="parent blogs_section" id='blogs' ref={ref} >
      { isBlogVisible && ( <div className="blogs_section-container container">
        <div
          className="section-title"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h4 className="special">
            {" "}
            <div className="dash"></div> My Blogs{" "}
          </h4>
          <h2
            className="main"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="300"
            id="blog-head"
          >
            Know One Cares
          </h2>
        </div>

        <div className="blog-card-box" data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="300" >



          <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide> <Blog_card/> </SwiperSlide>
        
      </Swiper>
        </div>

        <Link
          className="btn"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="900"
          data-aos-anchor="#blog-head"
          to="/blogs"
        >
          {" "}
          View all Blogs
        </Link>
      </div>)}
    </div>
  );
}

export default Blogs_section;
