import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import "../styles/Idols.scss";
import { EffectCreative } from "swiper/modules";

export default function Idols_slider() {
  return (
    <>
      <Swiper
        grabCursor={true}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        modules={[EffectCreative]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="Idols_slider" id="Idols">
            <div className="Idols_left">
              <h3> Rabindranath Tagore </h3>
              <p>
              Rabindranath Tagore's teachings have had a profound impact on my life. His call to love humanity, embodying the idea that "service to man is service to God," profoundly shapes my views on compassion, love, and sacrifice.
              </p>
            
            </div>
            <div className="Idols_right r11 bg-img-cover">
              <div className="bg-cover"></div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="Idols_slider">
            <div className="Idols_left">
              <h3> APJ Abdul Kalam </h3>
              <p>
              A.P.J. Abdul Kalam's journey resonates with me on a personal level.  His perspective on challenges as opportunities for personal growth, the significance of continuous learning, setting ambitious goals, and the values of humility and gratitude profoundly inspire me. His life's simplicity and down-to-earth nature serve as a constant reminder of the virtues I hold dear.
              </p>
            
            </div>
            <div className="Idols_right r12 bg-img-cover">
              <div className="bg-cover"></div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="Idols_slider">
            <div className="Idols_left">
              <h3> Nitin Gadkari </h3>
              <p>
              Another individual who profoundly inspires me is Nitin Gadkari. His journey from a small town to a national leader showcases the impact of visionary leadership and persistent determination. His commitment to innovation, efficiency, community engagement, and ethical leadership aligns closely with my values. 
              </p>
              
            </div>
            <div className="Idols_right r13 bg-img-cover">
              <div className="bg-cover"></div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
