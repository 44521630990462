import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import "../styles/Journey.scss";
import { EffectCreative } from "swiper/modules";

export default function Jour_slider() {
  return (
    <>
      <Swiper
        grabCursor={true}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        modules={[EffectCreative]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="jour_slider" id="journey">
            <div className="jour_left">
              <h3> School </h3>
              <p>
              During my school years, I discovered the profound value of humanity, the warmth of togetherness, and the joy of embracing life's adventures. This period was a journey of growth, where my peers and I, driven by a competitive spirit, aspired to reach the skies. Proudly, my fellow badge mates are now thriving in various fields, a testament to the dreams we once shared.
              </p>
              <p>
                Together, we faced the challenges presented by both time and
                society with unwavering enthusiasm, often so absorbed in our
                pursuits that difficulties seemed to fade into the background.
                The school became the crucible where the foundations of my
                character and ambition were laid, setting the stage for the
                remarkable journey that lay ahead.
              </p>
            </div>
            <div className="jour_right r1 bg-img-cover">
              <div className="bg-cover"></div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="jour_slider">
            <div className="jour_left">
              <h3> Family </h3>
              <p>
                The most inspiring stories in my life have emanated from the
                cherished narratives of my family—from the tales of my
                great-grandmother to the profound experiences of my grandfather
                and father. The legacy of the Nilangekar family extends deep
                into the pages of history, woven into the fabric of wars for
                freedom.
              </p>
              <p>
                As the first granddaughter of the family, I felt a tremendous
                responsibility to carry forward the legacy left by generations
                before me. Witnessing my grandfather's tireless efforts for the
                greater good, creating something meaningful from seemingly
                nothing, left an indelible mark on my character. Beyond family,
                this ethos guided my journey in work and life.
              </p>

              <p>
              The influences don't end with marriage; I've received constant support from my in-laws. The inspiration shifted from one family to another. My father-in-law's meticulous approach taught me the significance of organisation, enlightening my path of hard work in creating a world from scratch.
              </p>
            </div>
            <div className="jour_right r2 bg-img-cover">
              <div className="bg-cover"></div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="jour_slider">
            <div className="jour_left">
              <h3> Work </h3>
              <p>
                For me, work is not just a means of livelihood; it's a canvas
                where my passion paints the picture of purpose. I'm drawn to
                endeavors that create value and foster skill enhancement, always
                eager to incorporate them into my professional journey.
              </p>
              <p>
              As an ardent traveller, the allure of exploring new realms and acquiring diverse expertise is irresistible. I'm willing to embrace the challenge if there's a potential to turn it into a profession. With a background in PR and Branding, my proximity to marketing was undeniable. During my tenure at DIwise, I truly recognized my aptitude in the field. Being the most persuasive voice in the family offered me another avenue to articulate my strengths and convictions.
              </p>
            </div>
            <div className="jour_right r3 bg-img-cover">
              <div className="bg-cover"></div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
