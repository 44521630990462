import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import AOS from "aos";
import "aos/dist/aos.css";
import { useInView } from "react-intersection-observer";
import "../styles/About.scss";
import {BiSolidQuoteRight} from "react-icons/bi"
import {BiSolidQuoteLeft} from "react-icons/bi"
import {BsDashLg} from "react-icons/bs"
import { FaArrowUp } from "react-icons/fa";

function About() {
  const [isAboutVisible, setIsAboutVisible] = useState(true);

  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  useEffect(() => {
    AOS.init();

    if (inView) {
      setIsAboutVisible(true);
    } else {
      setIsAboutVisible(false);
    }
  }, [inView]);

  return (
    <div className="parent about" id="about" ref={ref}>
      {isAboutVisible && (
        <div className="about-container container">
          <div className="about-left">
            <div
              className="counter-box"
              data-aos="fade-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              data-aos-delay="0"
              id="about-couter"
            >
              <div className="count-top">
                <h2>
                  <CountUp
                    end={15}
                    delay={0.1}
                    duration={5}
                    startOnMount={true}
                  />{" "}
                </h2>
                <h3>+</h3>
              </div>
              <div
                className="count-bottom"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="1000"
                data-aos-anchor="#about-couter"
              >
                <h4>Years of Experience</h4>
              </div>
            </div>
          </div>
          <div className="about-right">
            <div
              className="quote"
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              data-aos-delay="0"
              id="about-quote"
            >
              <span className="quote-sym">
                <BiSolidQuoteLeft />
              </span>
              <h3>Be fearless in the pursuit of what sets your soul on fire</h3>
              <span className="quote-sym">
                <BiSolidQuoteRight />
              </span>
            </div>
            <div
              className="quote-desc"
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              data-aos-delay="550"
              data-aos-anchor="#about-quote"
            >
              <span className="dash"><BsDashLg /></span>
              <p>
                Success is woven into an individual's journey, measured by the
                impact on lives touched. Failures are certain, yet the resilience
                to rise and the unwavering persistence propel one to greater
                heights.
              </p>
            </div>
            {/* <div className="qualities">
              <div className="quality">
                <p>Hard Work</p>
              </div>
              <div className="quality">
                <p>Gratitude</p>
              </div>
              <div className="quality">
                <p>Curiosity</p>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default About;
