import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import Header from "./comps/Header";
import { useState } from "react";
import Footer from "./comps/Footer";
import blog from "./comps/Blog"
import Single_blog from "./comps/Single_blog";

function App() {
  const [isHeroVisible, setIsHeroVisible] = useState(false);
  console.log('%cSTOP!', 'color: red; font-size: 30px; font-weight: bold;');
  console.log('%cThis is a browser feature intended for developers.', 'font-size: 14px;');
  console.log('%cIf someone told you to copy-paste something here, it is a scam.', 'font-size: 14px;');
  return (
    <div className="App">
      <BrowserRouter>
        <Header isHero={isHeroVisible} setHero={setIsHeroVisible} />

        <Routes>
          <Route
            index
            element={<Home isHero={isHeroVisible} setHero={setIsHeroVisible} />}
          />
            <Route path='/blogs' Component={blog} />
            <Route path='/blogdetail/:id' Component={Single_blog} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
