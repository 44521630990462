import React, { useEffect, useState } from 'react'
import "../styles/Blogs_section.scss"
import { Link } from 'react-router-dom'
import img1 from "../assets/blog1.jpg"
import axios from 'axios'



function Blog_card(props) {
  const [data, setdata] = useState([])
  const fetchData = () => {
    axios.get("https://backend.prajaktamarwaha.com/api/blogs/")
      .then(res => setdata(res.data.reverse()))
      .catch()
  }
  useEffect(() => {
    fetchData();
  }, [])
  return (
    <>
      {data && data.slice(0, 3).map((data, index) => {
        return (
          <div className='blog-card' key={index}>
            <div className="img bg-img-cover" style={{ backgroundImage: `url(${data.image})` }}>
              <div className="img-grad"></div>
              <div className="cat"><p>{data.category && data.category.name}</p></div>
            </div>
            <div className="content">
              <h4>{data.title}</h4>
              <Link to={`/blogdetail/${data.id}`} className="btn2">Read More</Link>
            </div>
          </div>
        )
      })}

    </>

  )
}

export default Blog_card
