import React, { useState, useRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useInView } from "react-intersection-observer";

import "../styles/Pi.scss"
import { BiSolidQuoteLeft } from "react-icons/bi";
import { BiSolidQuoteRight } from "react-icons/bi";
import PiSlider from "./PiSlider";

function Personal_inspiration() {


  const [ispiVisible, setIspiVisible] = useState(true);

  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  useEffect(() => {
    AOS.init();

    if (inView) {
      setIspiVisible(true);
    } else {
      setIspiVisible(false);
    }
  }, [inView]);





  return (
    <div className='pi parent' id="inspiration" ref={ref}>
{
   ispiVisible &&     <div className="pi-container container">

<div
            className="section-title"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h4 className="special">
              {" "}
              <div className="dash"></div> My Inspiration{" "}
            </h4>
            <h2
              className="main"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
              id="blog-head"
            >
              Meet My Parents
            </h2>
          </div>

   

        <div className="quote">
            <span className="quote-sym"><BiSolidQuoteLeft /></span>
            <h3> The influence that isn't just a part of me, it's the very fabric of my being</h3>
            <span className="quote-sym"><BiSolidQuoteRight /></span>


            








        </div>

        <div className="pi-slider-box">

              <PiSlider/>
            </div>


        

   



        

        </div>
}

      
    </div>
  )
}

export default Personal_inspiration
