import React, { useEffect, useState } from 'react'
import "../styles/Single_blog.scss"
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'


const Single_blog = () => {
  const { id } = useParams()
  const [data, setdata] = useState([])
  const fetchData = () => {
    axios.get(`https://backend.prajaktamarwaha.com/api/blogs/${id}/`)
      .then(res => setdata(res.data))
      .catch()
  }
  useEffect(() => {
    fetchData()
  }, [])
  function formatDate(dateString) {
    // Assuming dateString is in ISO format (e.g., "2024-02-06T12:30:00")
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  return (
    <>

      
      <div className="layout">
        <div className="layout-container">
          <div className="top">
            <div
              className="imgbox"
              style={{ backgroundImage: `url(${data.image})` }}
            ></div>
            <div className="overlay">
              <h1>{data.title}</h1>
            </div>
          </div>
          <div className="mid">
            <div className="date">Upload Date : {formatDate(data.date)}</div>
            <div className="category">
             Category : {data.Category
                && data.Category
                  .name}
              
            </div>
          </div>
          <div className="bottom">
          <div className="data" dangerouslySetInnerHTML={{ __html: data.blog_content }} />
            <Link to="/blogs" className="btn">
              All Blogs
            </Link>
          </div>
        </div>
      </div>


    </>
  )
}

export default Single_blog
