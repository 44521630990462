import React from 'react'
import "../styles/Association.scss"
import Asso_slider from './Asso_slider';

function Association() {
  return (
    <div className="association about" >


        <Asso_slider/>






       
      
    </div>
  )
}

export default Association
