import React, { useEffect, useState } from "react";
import "../styles/Blog.scss";
import { Link } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import { Helmet } from "react-helmet";
import axios from "axios";
function Blog() {
  const [data, setdata] = useState([])
  const fetchData = () => {
    axios.get("https://backend.prajaktamarwaha.com/api/blogs/")
      .then(res => setdata(res.data))
      .catch()
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <>
      <Helmet >
        <title>Prajakta Marwaha's Journey: Business, Marketing, Travel, and Philanthropy Insights</title>
        <meta name='keywords' content='diwise, a working mothers tale of guild, dreams, and understanding, bouncing back Daring to fail' />
        <meta name='description' content='Discover Prajakta Marwaha insightful blog covering business, marketing, travel, and philanthropy. Stay informed and inspired with our diverse content. ' />
        <link rel='canonical' href='https://prajaktamarwaha.com/blogs' />

      </Helmet>
      <section className="blog">
        <div className="blog-container container">
          <h2 data-aos="zoom-in-left"></h2>

          <div className="blogs">
            {/* <div className="filters-box">
              <div className="search-box">
                <input type="text" placeholder="Search here..." />
                <div className="search-icon">
                  <IoIosSearch />
                </div>
              </div>

              <div className="date">
                <div className="from">
                  <label htmlFor="fromDate">Start Date</label>
                  <input type="date" />
                </div>
                <div className="to">
                  <label htmlFor="toDate">End Date</label>
                  <input type="date" />
                </div>
              </div>
              <select name="category" id="category">
                <option value="Uncategorized">No categories</option>
                <option value="Uncategorized">No categories</option>
                <option value="Uncategorized">No categories</option>
                <option value="Uncategorized">No categories</option>
              </select>

              <div className="btns">
                <button className="btn">Apply</button>
                <button className="btn">Reset</button>
              </div>
            </div> */}

            <div className="blog-box">
              {data && data.map((data, index) => {
                return (
                  <div
                    className="blog-element"
                    data-aos="zoom-in-left"
                    data-aos-delay="400"
                    key={index}
                  >
                    <div
                      className="image bg-img-contain"
                      style={{ backgroundImage: `url(${data.image})` }}
                    ></div>
                    <div className="content">
                      <p className="title">
                        {data.Category && data.Category.name}
                      </p>
                      <p className="category">
                        {data.title}
                      </p>
                      <Link to={`/blogdetail/${data.id}`} className="btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog;
