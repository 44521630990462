import React from 'react'
import '../styles/Footer.scss'
import logo from "../assets/logo-white.png";
import { Link } from "react-router-dom";
import { FaRegCopyright } from "react-icons/fa6";

function Footer() {
  return (
    <>
      <div className="parent Footer">
        <div className="Footer-container container">

        
            {/* <Link to="/" className="logo">
              <img src={logo} alt="" />
            </Link>
           */}



          {/* <div className="menu">
            <a href="/">Home</a>
            <a href="#about">About Me</a>
            <a href="#association">My Associations</a>
            <a href="#journey1">My Journey</a>
            <a href="#inspiration">My Inspiration</a>
            <a href="#idols">My Idols</a>
            <a href="#blogs">Blogs</a>
            <a href="#contact">Contact</a>
          </div> */}

          <div className="copy"><h4>
        <FaRegCopyright /> Prajakta Marwaha 2024
        </h4>
        
        </div>



        </div>

       
      </div>




    </>
  )
}

export default Footer
