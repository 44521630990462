import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import "../styles/Pi.scss";
import { EffectCreative } from "swiper/modules";

function PiSlider() {
  return (
    <>
    <Swiper
        grabCursor={true}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        modules={[EffectCreative]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="pi_slider" >
            <div className="pi_left">
              <h3> My Father </h3>
              <p>
              My father, my enduring inspiration, imparted profound lessons that shaped my character. His philosophy, 'Do good without seeking recognition,' echoes my philanthropic endeavours. He quietly sponsored education, supported communities, and left a legacy of altruism. His commitment to anonymity, revealed posthumously, deeply influences my approach, emphasising the profound impact of selfless actions.
              </p>
              
            </div>
            <div className="pi_right father bg-img-cover">
              <div className="bg-cover"></div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="pi_slider" >
            <div className="pi_left">
              <h3> My Mother </h3>
              <p>
              My mother's moral stories sculpted my character from my earliest days, shaping a personality rooted in care and humility. Raised watching my mother, her influence is deeply ingrained. She carries the torch of gentleness and humility, inherited from generations of women in our family. The emphasis on staying 'grounded,' a lesson echoing through her life's journey, has become my guiding principle. 'With great power comes great responsibility,' a timeless wisdom she imparted, resonates in every aspect of my life.
              </p>
              
            </div>
            <div className="pi_right mom  bg-img-cover">
              <div className="bg-cover"></div>
            </div>
          </div>
        </SwiperSlide>

       
        
      </Swiper>
    
    </>
  )
}

export default PiSlider
