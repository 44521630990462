import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../styles/Association.scss";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import sliderbg from "../assets/slider-bg.webp"

export default function Asso_slider() {
  return (
    <>
      <Swiper
        spaceBetween={10}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="asso-slide" id="association"  >
            <div
              className="asso-slide-bg asso1 bg-img-cover"
              style={{
                background: `url(${require("../assets/Assosiation-pandoza.jpg")})`,
              }}
            ></div>
            <div className="asso-slide-box" >
              <div className="asso-slide-box-left">
                <h2>Pandoza solution</h2>
                <p>
                  Pandoza Solutions emerged as a dedicated partner for aspiring
                  entrepreneurs and people in business in India and the UK,
                  offering comprehensive marketing strategies and consultations
                  to elevate businesses. From humble beginnings, it has grown
                  into a dynamic force, providing help on the path to success.
                </p>

                <a href="https://pandozasolutions.com/" target="_blank" className="btn">Visit Website</a>
              </div>
              <div className="asso-slide-box-right"></div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="asso-slide">
            <div
              className="asso-slide-bg asso2 bg-img-cover"
              style={{
                background: `url(${require("../assets/ti.jpg")})`,
              }}
            ></div>
            <div className="asso-slide-box">
              <div className="asso-slide-box-left">
                <h2>The Indian Journey</h2>
                <p>
                  The Indian Journey is more than a travel agency; it's a
                  passion project born out of a deep love for exploration.
                  Personally, curating bespoke holiday plans for both Indian and
                  international travelers, it’s a thrill to make travel dreams
                  into experiences.
                </p>

                <a href="https://theindianjourney.com/" target="_blank" className="btn">Visit Website</a>
              </div>
              <div className="asso-slide-box-right"></div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="asso-slide">
            <div
              className="asso-slide-bg asso3 bg-img-cover"
              style={{
                background: `url(${require("../assets/akka.webp")})`,
              }}
            ></div>
            <div className="asso-slide-box">
              <div className="asso-slide-box-left">
                <h2>Akka Foundation</h2>
                <p>
                Akka Foundation emerged as a heartfelt initiative driven by the realisation that many people face challenging lives due to a lack of awareness and limited access to essential assistance. My association with Akka is a personal commitment rooted in a profound desire to make a positive impact, providing support where needed most.
                </p>

                <a href="https://akkafoundation.in/" target="_blank"
                 className="btn">Visit Website</a>
              </div>
              <div className="asso-slide-box-right"></div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="asso-slide">
            <div
              className="asso-slide-bg asso4 bg-img-cover"
              style={{
                background: `url(${require("../assets/diwise-d-newww.jpg")})`,
              }}
            ></div>
            <div className="asso-slide-box">
              <div className="asso-slide-box-left">
                <h2>Diwise</h2>
                <p>
                DIwise, a dynamic digital marketing agency, has evolved into a vibrant hub, continually innovating to foster new connections, collaborations, and brand-building initiatives on a global scale. Actively expanding internationally, our presence extends to the UK, making DIwise the go-to platform for those seeking international exposure.
                </p>

                <a href="https://www.diwise.uk/" target="_blank" className="btn">Visit Website</a>
              </div>
              <div className="asso-slide-box-right"></div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
