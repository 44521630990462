import React, { useState,useEffect } from "react";
import "../styles/Header.scss";
import logo from "../assets/logo-white.png";
import navbg from "../assets/nav-bg.png";
import { Link } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { RiLinkedinFill } from "react-icons/ri";
import AOS from "aos";
import "aos/dist/aos.css";

function Header(props) {
  const [isopen, setisopen] = useState(false);

  useEffect(() => {

    AOS.init();

    const handleScroll = () => {
      
      if (window.scrollY > 15) {
        setisopen(false); props.setHero(true)
      } else {
        
      }
    };

    
    window.addEventListener('scroll', handleScroll);





  }, []);








  return (
    <>
      <div className="parent header"
            data-aos="fade-down"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            data-aos-delay="0">
        <div className="header-container container">
          <Link to="/" className="logo">
            <img src={logo} alt="" />
          </Link>

          <div className="menu" onClick={()=> {setisopen(true); props.setHero(false)}}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>

      <div className={isopen ? "nav parent navopen" : "nav parent"}>
        <div className="close"  onClick={()=> {setisopen(false); props.setHero(true)}}>
          <span></span>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <div className="nav-container container">
          <div className="links">
            
        
            <Link to='/' onClick={()=> {setisopen(false); props.setHero(true)}}>Home</Link>
            <a href="#about" onClick={()=> {setisopen(false); props.setHero(true)}}>About Me</a>
            <a href="#association" onClick={()=> {setisopen(false); props.setHero(true)}}>My Associations</a>
            <a href="#journey1" onClick={()=> {setisopen(false); props.setHero(true)}}>My Journey</a>
            <a href="#inspiration" onClick={()=> {setisopen(false); props.setHero(true)}}>My Inspiration</a>
            <a href="#Idols" onClick={()=> {setisopen(false); props.setHero(true)}}>My Idols</a>
            <a href="#blogs" onClick={()=> {setisopen(false); props.setHero(true)}}>Blogs</a>
            <a href="#contact" onClick={()=> {setisopen(false); props.setHero(true)}}>Contact</a>
          </div>

          <div className="icons">
            <a href="https://www.linkedin.com/in/prajakta-marwaha-507b6410a/" target="_blank" className="icon-box b1">
              <RiLinkedinFill/>
            </a>
            <a href="https://www.instagram.com/prajakta.marwaha.official/" target="_blank" className="icon-box b2">
              <RiInstagramFill/>
            </a>
            <a href="https://www.facebook.com/profile.php?id=61554416354249" target="_blank" className="icon-box b3">
            <FaFacebookF />
            </a>
            <a href="https://twitter.com/MarwahaPrajakta" target="_blank" className="icon-box b4">
            <FaXTwitter />
            </a>
          </div>


          
        </div>

      </div>
    </>
  );
}

export default Header;
