import React, { useState, useRef, useEffect } from "react";
import "../styles/Contact.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { useInView } from "react-intersection-observer";
import { IoMdMail } from "react-icons/io";
import { RiLinkedinFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast, Flip } from 'react-toastify';
function Contact() {
  const [isContactVisible, setIsContactVisible] = useState(true);
  const [formdata, setformdata] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
  })
  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  useEffect(() => {
    AOS.init();

    if (inView) {
      setIsContactVisible(true);
    } else {
      setIsContactVisible(false);
    }
  }, [inView]);
  const submitHandler = (e) => {
    e.preventDefault()
    axios.post("https://backend.prajaktamarwaha.com/api/contacts/", formdata)
      .then(res => {
        toast.success('Thank you for contacting me.', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Flip,
        });
        setformdata({
          name: "",
          mobile: "",
          email: "",
          message: ""
        });
      })
      .catch()
  }
  return (
    <div className="parent contact" id="contact" ref={ref}>
      {isContactVisible && (
        <><div className="full-bg1 bg-img-cover contact-bg"></div>
          <div className="contact-container container">
            <div className="contact-top">
              <div
                className="section-title"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h4 className="special">
                  {" "}
                  <div className="dash"></div> Contact Me{" "}
                </h4>
                <h2
                  className="main"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="300"
                >
                  Let’s talk!
                </h2>
              </div>
            </div>

            <div className="contact-bottom">
              <div className="contact-left">
                <div className="contact-box" data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="300">
                  <h3>
                    <span>Let's connect </span>- coffee style, not just business.
                  </h3>
                  <div className="line"></div>

                  <a className="icon-box" href="mailto:prajakta.marwaha@diwise.uk" target="_blank" >
                    <span className="icon">
                      <IoMdMail />
                    </span>
                    <p>
                      prajakta.marwaha@diwise.uk
                    </p>
                  </a>
                  <div className="icon-box ib2">
                    <a href="https://www.linkedin.com/in/prajakta-marwaha-507b6410a/" target="_blank" className="icon i">
                      <RiLinkedinFill />
                    </a>
                    <a href="https://www.instagram.com/prajakta.marwaha.official/" target="_blank" className="icon i">
                      <RiInstagramFill />
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=61554416354249" target="_blank" className="icon i">
                      <FaFacebookF />
                    </a>
                    <a href="https://twitter.com/MarwahaPrajakta" target="_blank" className="icon i">
                      <FaXTwitter />
                    </a>

                  </div>
                </div>
              </div>
              <div className="contact-right">
                <form action="#" onSubmit={submitHandler}>
                  <input
                    type="text"
                    placeholder="Your Name*"
                    id="name"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="00"
                    value={formdata.name}
                    onChange={e => setformdata({ ...formdata, name: e.target.value })}
                    required

                  />
                  <input
                    type="email"
                    placeholder="Your Email*"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="300"
                    value={formdata.email}
                    onChange={e => setformdata({ ...formdata, email: e.target.value })}
                    required

                  />
                  <input
                    type="number"
                    placeholder="Your Contact*"
                    id="name"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="00"
                    value={formdata.mobile}
                    onChange={e => setformdata({ ...formdata, mobile: e.target.value })}
                    required

                  />
                  {/* <input type="email" placeholder='Your Phone*' /> */}
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="Your Message goes here ..."
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="600"
                    value={formdata.message}
                    onChange={e => setformdata({ ...formdata, message: e.target.value })}
                  ></textarea>
                  <button
                    type="submit"
                    className="btn"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="900"
                    data-aos-anchor="#name"
                  >
                    {" "}
                    Let`s Connect{" "}
                  </button>
                </form>
              </div>
            </div>
          </div></>
      )}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        limit={1}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition="Flip"
      />
    </div>
  );
}

export default Contact;
