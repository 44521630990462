import "../styles/Journey.scss";
import Jour_slider from "./Jour_slider";
import React, { useState, useRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useInView } from "react-intersection-observer";

function Journey() {
  const [isJourneyVisible, setIsJourneyVisible] = useState(true);

  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  useEffect(() => {
    AOS.init();

    if (inView) {
      setIsJourneyVisible(true);
    } else {
      setIsJourneyVisible(false);
    }
  }, [inView]);

  return (
    <div className="parent journey" ref={ref} id="journey1">
      {isJourneyVisible && (
        <div className="journey-container container">
          <div
            className="section-title"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h4 className="special">
              {" "}
              <div className="dash"></div> My Journey{" "}
            </h4>
            <h2
              className="main"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
              id="blog-head"
            >
              Roots and Beginnings
            </h2>
          </div>

          <div
            className="journey-slider-box"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="600"
          >
            <Jour_slider />
          </div>
        </div>
      )}
    </div>
  );
}

export default Journey;
