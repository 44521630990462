import "../styles/Journey.scss";
import Idols_slider from "./Idols_slider";
import React, { useState, useRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useInView } from "react-intersection-observer";

function Idols() {
  const [isIdolsVisible, setIsIdolsVisible] = useState(true);

  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  useEffect(() => {
    AOS.init();

    if (inView) {
      setIsIdolsVisible(true);
    } else {
      setIsIdolsVisible(false);
    }
  }, [inView]);

  return (
    <div className="parent Idols" ref={ref} id="Idols">
      {isIdolsVisible && (
        <div className="Idols-container container">
          <div
            className="section-title"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h4 className="special">
              {" "}
              <div className="dash"></div> My Idols{" "}
            </h4>
            <h2
              className="main"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
              id="blog-head"
            >
              Life's Inspirations
            </h2>
          </div>

          <div
            className="Idols-slider-box"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="600"
          >
            <Idols_slider />
          </div>
        </div>
      )}
    </div>
  );
}

export default Idols;
