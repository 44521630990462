import React ,{useState, useEffect} from 'react'
import Hero from '../comps/Hero'
import About from '../comps/About'
import Association from '../comps/Association'
import Idols from '../comps/Idols'
import Journey from '../comps/Journey'
import Personal_inspiration from '../comps/Personal_inspiration'
import { FaArrowUp } from "react-icons/fa";
import "../pages/Home.scss"

import Blogs_section from '../comps/Blogs_section';
import Contact from '../comps/Contact'
import { Helmet } from 'react-helmet'

function Home(props) {

  const [isBtnVisible , setisBtnVisible]= useState(false);

  

  useEffect(() => {
    const handleScroll = () => {
      
      if (window.scrollY > 40) {
        setisBtnVisible(true);
      } else {
        setisBtnVisible(false);
      }
    };

    
    window.addEventListener('scroll', handleScroll);

    

  }, []);

  const top = () => {window.scrollTo(0,0);};


  return (
    <>
    <Helmet >
      <title>Business Insights with Prajakta Marwaha: Navigating Indian and UK Markets with Diwise Marketing Strategies</title>
      <meta name='keywords' content='prajakta marwaha, women success in individual journey, a business women, business in india and uk, marketing strategies, business consulting, diwise, travel agency, indian journey, international travelers, akka foundation, marketing agency' />
      <meta name='description' content='Prajakta Marwaha, a successful businesswoman, navigates both Indian and UK business scenes. Her marketing agency, Diwise, is known for creative strategies. ' />
      <link rel='canonical' href='https://prajaktamarwaha.com' />
    </Helmet>
    <div className='superparent' >
      {/* back to top button */}
      <button onClick={top}  className={ isBtnVisible ? "top_btn opacity" :"top_btn"}><FaArrowUp /></button>


     <Hero isHero={props.isHero} setHero={props.setHero}/> 
     <About/>
     <Association/>
     <Journey/>
     <Personal_inspiration/>
     <Idols />
     <Blogs_section/>
     <Contact/>



    </div>
    </>
 
  )
}

export default Home
